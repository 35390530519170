import _ from "lodash";
import { useStoreDiagram } from "..";
import { handlePortId, nodeId } from "../../config";
import { getToolDiagram } from "../global/diagram";

export const addNewNodeInterface = (set, get, messages, shouldCreateLink) => {
	try {
		const fromName = "addNewNodeInterface";
		messages.map((message) => {
			if (!message.handles) {
				message.handles = {};
			}

			if (message.id === nodeId.DRAFT) {
			} else {
				if (message.mode === "UNION-MESSAGE") {
					const button = message.messages[message.messages.length - 1];
					const isButton = button.mode === "BUTTON";
					if (isButton) {
						message.handles[handlePortId.default] = fromName;
						/** support for multi output */
						Object.values(button.input.options).forEach((item) => {
							message.handles[item.id] = fromName;
						});
					} else {
						if (message.id === nodeId.END) {
							message.handles[handlePortId.left] = fromName;
						} else {
							message.handles[handlePortId.right] = fromName;
						}
					}
				} else if (message.mode === "LINE/RICH-MENU") {
					message.handles[handlePortId.default] = fromName;
					message.handles[handlePortId.right] = fromName;
					/** support for multi output */
					Object.values(message.input.coordinates).forEach((coord) => {
						message.handles[coord.id] = fromName;
					});
				} /*  else if (message.mode === 'LINE/IMAGE-MAP') {
						message.handles[handlePortId.default);
						message.handles[handlePortId.right);
						* support for multi output
						Object.values(message.input.coordinates).forEach((coord) => {
							message.handles[coord.id)
						})
					}  */ else if (message.mode === "YESNO") {
					message.handles[handlePortId.default] = fromName;
					message.handles[handlePortId.yes] = fromName;
					message.handles[handlePortId.no] = fromName;
				} else if (message.mode === "GOOD-BYE") {
				} else if (message.mode === "JUMP-TO") {
				} else if (message.mode === "GOOGLE-SPREADSHEET") {
				} else if (message.mode === "CONDITION") {
					message.handles[handlePortId.true] = fromName;
					message.handles[handlePortId.false] = fromName;
				} else if (message.mode === "AB-TEST") {
					message.handles[handlePortId.a] = fromName;
					message.handles[handlePortId.b] = fromName;
				} else if (message.mode === "ORDERS-TEMPLATE") {
					message.handles[handlePortId.right] = fromName;
					message.handles[handlePortId.checkout] = fromName;
				} else if (message.mode === "SCALE") {
					message.handles[handlePortId.default] = fromName;
					const { from, to } = message.input;
					_.range(from, to + 1, from <= to ? 1 : -1).forEach((number) => {
						message.handles[`scale_${number}`] = fromName;
					});
				} else if (message.mode === "LINE/BUTTON-TEMPLATE") {
					message.handles[handlePortId.default] = fromName;
					message.handles[handlePortId.right] = fromName;
					message.config.actions.forEach((_, index) => {
						message.handles[`action_${index}`] = fromName;
					});
				} else if (message.mode === "LINE/CONFIRM-TEMPLATE") {
					message.handles[handlePortId.default] = fromName;
					message.handles[handlePortId.right] = fromName;
					message.handles[handlePortId.yes] = fromName;
					message.handles[handlePortId.no] = fromName;
				} else if (message.mode.split("/")[0] === "FLEX") {
					message.handles[handlePortId.right] = fromName;
					Object.keys(message.actions || {}).forEach((key) => {
						message.handles[key] = fromName;
					});
				} else if (message.mode === "LINE/CAROUSEL-TEMPLATE") {
					message.handles[handlePortId.default] = fromName;
					message.handles[handlePortId.right] = fromName;
					message.config.columns.forEach((column, index) => {
						column.actions.forEach((_, cIndex) => {
							message.handles[`action_${index}_${cIndex}`] = fromName;
						});
					});
				} else if (message.mode === "LINE/IMAGE-CAROUSEL-TEMPLATE") {
					message.handles[handlePortId.default] = fromName;
					message.handles[handlePortId.right] = fromName;
					message.config.columns.forEach((_, index) => {
						message.handles[`action_${index}`] = fromName;
					});
				} else if (message.mode === "PRODUCT-CAROUSEL-TEMPLATE") {
					message.handles[handlePortId.right] = fromName;
					message.handles[handlePortId.select] = fromName;
				} else if (message.mode === "INTRO") {
					message.handles[handlePortId.right] = fromName;
				} else if (message.mode === "BUSINESS-HOURS") {
					message.handles[handlePortId.open] = fromName;
					message.handles[handlePortId.closed] = fromName;
				} else {
					message.handles[handlePortId.right] = fromName;
				}
			}

			return message;
		});

		getToolDiagram("addNodes")(messages);

		// if include to create link
		if (shouldCreateLink) {
			const edges = [];
			messages.forEach((message) => {
				if (message.trigger) {
					edges.push(
						...Object.entries(message.trigger).map(
							([sourcehandle, targetId]) => {
								const source = {
									nodeId: message.id,
									handleId: sourcehandle,
								};

								const target = {
									nodeId: targetId,
									handleId: handlePortId.entry,
								};

								return { source, target };
							}
						)
					);
				}
			});

			useStoreDiagram.getState().addEdge(edges);
			useStoreDiagram.getState().endDragEdge();
		}
	} catch (err) {
		console.error("[addNewNodeInterface] ", err);
	}
};
