import { useStoreDiagram } from "..";
import { DURATION_VIEW_PORT, nodeId, nodeProps } from "../../config";
import { getToolDiagram } from "../global/diagram";

export const createDraftNode = (set, get, position) => {
    try {
        const newMessage = {
            ...nodeProps,
            mode: "MESSAGE-SELECTION",
            id: nodeId.DRAFT,
            position: {
                ...position,
            },
        };

        getToolDiagram("setNodes")((nds) => nds.concat([newMessage]));

        setTimeout(() => {
            changeViewport();
        }, 100);
    } catch (err) {
        console.error("[createDraftNode] ", err);
    }
};

const changeViewport = () => {
    const node = getToolDiagram("getNode")(nodeId.DRAFT);
    if (node) {
        const zoom = 1.2;
        const x =
            -node.position.x -
            250 * zoom +
            window.innerWidth / 2 -
            (node.measured?.width / 2) * zoom || 0;
        const y =
            -node.position.y * zoom +
            window.innerHeight / 2 -
            (node.measured?.height / 2) * zoom || 0;

        getToolDiagram("setViewport")(
            { x, y, zoom: zoom },
            { duration: DURATION_VIEW_PORT }
        );
    }
};
