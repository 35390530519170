import ComponentNode from "./node";
import React, { memo, useEffect, useState } from "react";
import useDetachNodes from "../feature/dynamicGrouping/useDetachNodes";
import { miniavs } from "@dicebear/collection";
import { createAvatar } from "@dicebear/core";
import { NodeToolbar, useReactFlow, useStore } from "@xyflow/react";
import { useTranslation } from "react-i18next";
import { useStoreUser } from "../feature/collaborative/state/storeUser";

function NodeModel(props) {
    const { id } = props;
    const { t } = useTranslation(["board"]);

    const hasParent = useStore((store) => !!store.nodeLookup.get(id)?.parentId);

    const detachNodes = useDetachNodes();
    const onDetach = () => detachNodes([id]);

    const { getNode } = useReactFlow();
    const node = getNode(id);
    const [isLocked, setLock] = useState(false);
    const users = useStoreUser((state) => state.users);
    const [userNow, setUserNow] = useState();

    const avatarsImg = (id, color) => {
        return createAvatar(miniavs, {
            size: 128,
            seed: id,
            backgroundColor: [color?.slice(1, 7)],
        }).toDataUriSync();
    };

    useEffect(() => {
        const CLIENT_ID_COLLABORATIVE =
            window.collaborativeYjs?.CLIENT_ID_COLLABORATIVE;

        if (CLIENT_ID_COLLABORATIVE) {
            if (
                node.CLIENT_ID_SELECT &&
                node.CLIENT_ID_SELECT !== CLIENT_ID_COLLABORATIVE
            ) {
                const userNow = users[node.CLIENT_ID_COLLABORATIVE];
                if (userNow) {
                    setUserNow(userNow);
                    setLock(true);
                    return;
                }
            }
        }
        setLock(false);
    }, [node.CLIENT_ID_COLLABORATIVE, node.CLIENT_ID_SELECT, users]);

    return (
        <>
            {isLocked && (
                <div
                    style={{
                        zIndex: 1002,
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                        backgroundColor: "#000000db",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "row",
                        borderRadius: "5px",
                        outline: "5px black solid",
                    }}
                >
                    <img
                        alt={userNow?.email || ""}
                        src={avatarsImg(userNow?.id, userNow?.color)}
                        style={{
                            zIndex: 1003,
                            height: "50px",
                            borderRadius: "100%",
                            padding: "5px",
                        }}
                    />
                    {userNow?.email || ""}
                </div>
            )}

            <NodeToolbar className="nodrag">
                {hasParent && <button onClick={onDetach}>{t("group.Detach")}</button>}
            </NodeToolbar>
            <ComponentNode {...props} />
        </>
    );
}

export default memo(NodeModel);
