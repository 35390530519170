import { decompress } from "@cloudpss/zstd";

// Function to handle received data
const decompressedZstd = async (compressedData) => {
    const compressed = new TextEncoder().encode(compressedData);

    // Parallel decompression for large data sets
    const decompressed = await decompress(compressed);

    // Convert back to string and process the message
    const message = new TextDecoder().decode(decompressed);
    console.log('Received decompressed message:', message);
    return (message)
}

const bindWithChunking = (channel, event, callback) => {
    let events = {};

    function handleChunkedEvent(data) {
        let { id, index, chunk, final } = data;

        if (!events[id]) {
            events[id] = { chunks: [], receivedFinal: false };
        }

        let ev = events[id];
        ev.chunks[index] = chunk;

        if (final) {
            ev.receivedFinal = true;
        }

        if (ev.receivedFinal && ev.chunks.length === Object.keys(ev.chunks).length) {
            const finalCallbackData = decompressedZstd(ev.chunks.join(""))
            callback(finalCallbackData);
            delete events[id];
        }
    }

    channel.bind(event, callback);
    channel.bind("chunked-" + event, handleChunkedEvent);
}

export default bindWithChunking